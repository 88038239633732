var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:_vm.refModal,attrs:{"id":"facility-manager-modal","title":("Quản trị viên: " + (_vm.facility.name)),"ok-only":"","scrollable":"","size":"lg","ok-title":"Đóng","ok-variant":"secondary","hide-header-close":true,"no-close-on-backdrop":""},on:{"show":_vm.onOpen,"hidden":_vm.resetModal,"ok":_vm.resetModal}},[_c('div',{staticClass:"manager-list-container"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-manager",modifiers:{"modal-manager":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.onEditItem(null)}}},[_vm._v("Thêm ")]),_c('vue-good-table',{staticClass:"pt-2",attrs:{"columns":_vm.columns,"rows":_vm.data,"select-options":{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'title')?_c('span',[_c('span',[_vm._v(_vm._s(props.row.title))]),_c('small',[_c('br'),_vm._v(_vm._s(props.row.content))])]):(props.column.field === 'time')?_c('span',[_vm._v(" "+_vm._s(_vm.resolveTimeDuration(props.row))+" ")]):(props.column.field === 'action')?_c('span',[_c('table-actions',{attrs:{"name":"Quản trị viện","modal":"modal-manager","hide-delete":true},on:{"on-delete":function($event){return _vm.onDelete(props.row)},"on-edit":function($event){return _vm.onEditItem(props.row)}}},[_c('template',{slot:"other-buttons"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-danger",modifiers:{"hover":true,"v-danger":true}},{name:"b-modal",rawName:"v-b-modal.modal-set-password",modifiers:{"modal-set-password":true}}],staticClass:"btn-icon ml-50",attrs:{"size":"sm","variant":"danger","title":"Đổi mật khẩu"},on:{"click":function($event){return _vm.onEditItem(props.row)}}},[_c('feather-icon',{attrs:{"icon":"KeyIcon"}})],1)],1)],2)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center text-muted"},[_vm._v(" Không có bản ghi nào! ")])])])],1),_c('manager-modal',{attrs:{"item":_vm.item},on:{"refetch-data":_vm.fetchData}}),_c('set-password-modal',{attrs:{"doctor":_vm.item}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }